import styled from "styled-components";

export const StyledLogoLink = styled.a`
  display: flex;
  width: 237.8px;

  @media (max-width: 768px) {
    width: 118.9px;
  }
`;
