import React from "react";

import { HeaderWrapper, StyledHeader } from "./Header.components";
import Logo from "./Logo";
import Nav from "./Nav";

const Header: React.FC = () => {
  return (
    <StyledHeader>
      <HeaderWrapper>
        <Logo />
        <Nav />
      </HeaderWrapper>
    </StyledHeader>
  );
};

export default Header;
