export const navLinks = [
  // {
  //   text: "About app",
  //   link: "/about-app/",
  // },
  // {
  //   text: "Blog",
  //   link: "/blog/",
  // },
];
