const theme = {
  colors: {
    white: "#FFF",
    black: "#000",
    blue: "#1875E1",
    lightBlue: "#C6E0FF",
    darkBlue: "#211B54",
    lightPurple: "#C6D3FF",
    lightTurquoise: "#A9F5F1",
    lightGreen: "#A9F5B1",
    red: "#FF440A",
    grey: "#757575",
  },
  border: {
    width: {
      s: "1px",
      m: "2px",
    },
    borderRadius: "4px",
  },
  fonts: {
    sizes: {
      xs: "14px",
      s: "16px",
      base: "18px",
      m: "20px",
      l: "24px",
      xl: "32px",
      xxl: "40px",
      xxxl: "48px",
    },
    lineHeights: {
      s: "16px",
      base: "24px",
      m: "32px",
      l: "40px",
      xl: "64px",
    },
    weights: {
      normal: 500,
      bold: 600,
      bolder: 700,
    },
  },
} as const;

export default theme;

export type ThemeType = typeof theme;
