import Link from "next/link";
import React from "react";

import { NavLink, StyledNav } from "./Nav.components";
import { navLinks } from "./navLinks";

const Nav = () => {
  return (
    <StyledNav>
      {navLinks.map(({ text, link }) => (
        <Link passHref key={text} href={link}>
          <NavLink>{text}</NavLink>
        </Link>
      ))}
    </StyledNav>
  );
};

export default Nav;
