import styled from "styled-components";

import Wrapper from "../Wrapper";

export const StyledHeader = styled.header`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightBlue};
`;

export const HeaderWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--nav-height, 64px);
`;
