import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 1600px;
  padding: 0 32px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;

export default Wrapper;
