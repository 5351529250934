import { FC } from "react";
import Link from "next/link";
import Image from "next/image";

import StorybaseLogo from "../../../assets/images/logo.svg";
import { StyledLogoLink } from "./Logo.components";

const Logo: FC = () => {
  return (
    <Link passHref href="/">
      <StyledLogoLink>
        <Image src={StorybaseLogo} alt="Logo" />
      </StyledLogoLink>
    </Link>
  );
};

export default Logo;
