import { createGlobalStyle } from "styled-components";
import { ThemeType } from "./theme";

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    font-family: 'Work Sans', sans-serif;
    font-size: 100%;
    font-display: fallback;

    --nav-height: 64px;

    @media (max-width: 768px) {
      --nav-height: 40px;
    }
  }

  input {
    font-family: 'Work Sans', sans-serif;
  }

  body,
  p,
  h1,
  h2,
  h3,
  h4,
  ul {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    font-size: ${({ theme }) => theme.fonts.sizes.s};
    line-height: ${({ theme }) => theme.fonts.lineHeights.m};
  }

  p {
    font-size: ${({ theme }) => theme.fonts.sizes.base};
    line-height: ${({ theme }) => theme.fonts.lineHeights.m};
  }

  h1 {
    font-size: ${({ theme }) => theme.fonts.sizes.xxl};
    line-height: ${({ theme }) => theme.fonts.lineHeights.xl};

    @media (max-width: 1024px) {
      font-size: ${({ theme }) => theme.fonts.sizes.xl};
      line-height: ${({ theme }) => theme.fonts.lineHeights.m};
    }
    
    @media (max-width: 768px) {
      font-size: ${({ theme }) => theme.fonts.sizes.m};
    }
  }

  h2 {
    line-height: ${({ theme }) => theme.fonts.lineHeights.l};

    @media (max-width: 1024px) {
      line-height: ${({ theme }) => theme.fonts.lineHeights.m};
    }
    
    @media (max-width: 768px) {
      font-size: ${({ theme }) => theme.fonts.sizes.base};
    }
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export default GlobalStyle;
