import styled, { css } from "styled-components";

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
`;

export const NavLink = styled.a`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.s};
    font-weight: ${theme.fonts.weights.bold};
    line-height: ${theme.fonts.lineHeights.m};
    color: ${theme.colors.black};
  `}

  position: relative;

  & + & {
    margin-left: 2em;
  }
`;
